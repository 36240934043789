import { render, staticRenderFns } from "./IndexPage.vue?vue&type=template&id=b26e8762&scoped=true&"
import script from "./IndexPage.vue?vue&type=script&lang=ts&"
export * from "./IndexPage.vue?vue&type=script&lang=ts&"
import style0 from "./IndexPage.vue?vue&type=style&index=0&id=b26e8762&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b26e8762",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/workspace/SPP/spp-erp-fe-development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b26e8762')) {
      api.createRecord('b26e8762', component.options)
    } else {
      api.reload('b26e8762', component.options)
    }
    module.hot.accept("./IndexPage.vue?vue&type=template&id=b26e8762&scoped=true&", function () {
      api.rerender('b26e8762', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user-management/privilege/IndexPage.vue"
export default component.exports