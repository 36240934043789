











































































































































import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { GENERAL_JOURNAL_STATUS } from "@/models/enums/general-journal.enum";
import { Messages } from "@/models/enums/messages.enum";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { ResponseListGeneralJournal } from "@/models/interface/generaljournal.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { IAuthorities } from "@/models/interfaces/auth.interface";
import { generalJournalServices } from "@/services/generaljournal.service";
import LocalStorageService from "@/services/LocalStorage.service";
import { masterServices } from "@/services/master.service";
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "ListJournal",
  mixins: [MNotificationVue],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: this.$form.createForm(this, { name: "listJournal" }),
      limit: 10 as number,
      page: 0 as number,
      search: "" as string,
      loadingTable: false as boolean,
      dataListJournal: {} as ResponseListGeneralJournal,
      currentPage: 1 as number,
      columnsTable: [
        {
          title: this.$t("lbl_document_number"),
          dataIndex: "name",
          key: "docNumber",
          sorter: true,
          // width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "date",
          key: "date",
          sorter: true,
          // width: 100,
          scopedSlots: { customRender: "isDate" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          sorter: false,
          // width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_document_reference"),
          dataIndex: "documentReference",
          key: "docReference",
          sorter: true,
          // width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_amount"),
          dataIndex: "total",
          key: "amount",
          sorter: false,
          // width: 100,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_source"),
          dataIndex: "source",
          key: "source",
          sorter: false,
          // width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          key: "status",
          sorter: false,
          // width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_action"),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: [] as string[],
          // width: 120,
          align: "center",
          // fixed: "right",
        },
      ],
      formRules: {
        docNumber: {
          label: "lbl_document_number",
          name: "Doc Number",
          placeholder: "lbl_document_number",
          decorator: [
            "docNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description",
          decorator: ["description"],
        },
        documentReference: {
          label: "lbl_document_reference",
          name: "documentReference",
          placeholder: "lbl_document_reference",
          decorator: ["documentReference"],
        },
        dateFrom: {
          label: "lbl_date_from",
          name: "Date From",
          placeholder: "lbl_date_from",
          decorator: [
            "dateFrom",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        dateTo: {
          label: "lbl_date_to",
          name: "Date To",
          placeholder: "lbl_date_to",
          decorator: [
            "dateTo",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "lbl_status",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        source: {
          label: "lbl_source",
          name: "source",
          placeholder: "lbl_source",
          decorator: [
            "source",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      privileges: [] as IAuthorities[],
      journalSources: [] as ResponseListMaster[],
      journalStatuses: [] as ResponseListMaster[],
      loading: {
        status: false,
        source: false,
      },
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
    allowCreateUpdate(): boolean {
      return !!this.privileges.find(
        x =>
          x.key === "general-journal" &&
          x.privilege.read &&
          (x.privilege.update || x.privilege.create)
      );
    },
    allowRead(): boolean {
      return !!this.privileges.find(
        x => x.key === "general-journal" && x.privilege.read
      );
    },
  },
  created(): void {
    this.loadPrivileges();
    this.setPrivilegeAct();
    this.getListStatus();
    this.getListSource();
  },
  methods: {
    moment,
    getListStatus(): void {
      this.loading.status = true;
      masterServices
        .listMaster({ name: "JOURNAL_STATE" })
        .then(res => {
          this.journalStatuses = res;
        })
        .finally(() => (this.loading.status = false));
    },
    getListSource(): void {
      this.loading.source = true;
      masterServices
        .listMaster({ name: "JOURNAL_SOURCE" })
        .then(res => {
          this.journalSources = res;
        })
        .finally(() => (this.loading.source = false));
    },
    loadPrivileges(): void {
      this.privileges = LocalStorageService.loadUserPrivilege();
    },
    setPrivilegeAct(): void {
      const colAct = this.columnsTable.find(x => x.dataIndex === "operation");
      if (!colAct) return;
      if (!this.allowCreateUpdate) return;
      colAct.button = ["view"] as string[];
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 0;
      this.currentPage = 1;
      this.getListGeneralJournal(true);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page - 1;
      this.currentPage = response.page;
      this.getListGeneralJournal(true);
    },
    reponseViewTable({ data }): void {
      let { id, status } = data;
      if (this.allowCreateUpdate) {
        this.editDocument(id, status);
      } else if (this.allowRead) {
        this.viewDocument(id);
      }
    },
    viewDocument(id: string): void {
      this.$router.push({ name: "generaljournal.detail", params: { id } });
    },
    editDocument(id: string, status: GENERAL_JOURNAL_STATUS): void {
      if (status === GENERAL_JOURNAL_STATUS.POSTED) {
        this.$router.push({ name: "generaljournal.detail", params: { id } });
      } else if (status === GENERAL_JOURNAL_STATUS.SUBMITTED) {
        this.$router.push({ name: "generaljournal.update", params: { id } });
      }
    },
    submitForm(): void {
      this.currentPage = 1;
      this.handleFind(this.form.getFieldsValue());
    },
    handleFind(values): void {
      let arrSearch: string[] = [];
      this.search = "";
      Object.keys(values).forEach(key => {
        if (values[key]) {
          if (key === "dateFrom")
            arrSearch.push(
              `date>=${this.moment(values.dateFrom)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .format()}`
            );
          if (key === "dateTo")
            arrSearch.push(
              `date<=${this.moment(values.dateTo)
                .set("hour", 23)
                .set("minute", 59)
                .set("second", 59)
                .format()}`
            );
          if (key === "docNumber") arrSearch.push(`name~${values.docNumber}`);

          if (key === "description")
            arrSearch.push(`description~*${values.description}*`);

          if (key === "documentReference")
            arrSearch.push(`documentReference~*${values.documentReference}*`);

          if (key === "status") {
            arrSearch.push(`status~${values.status}`);
          }

          if (key === "source") {
            arrSearch.push(`source~${values.source}`);
          }
        }
      });

      if (arrSearch.length > 0) this.search = arrSearch.join("_AND_");
      this.getListGeneralJournal(false);
    },
    getListGeneralJournal(pagination: boolean): void {
      let params = {
        limit: this.limit,
        sorts: "createdDate:desc",
        page: pagination ? this.page : 0,
      } as RequestQueryParamsModel;
      if (this.search) {
        params.search = this.search;
      }
      this.loadingTable = true;
      generalJournalServices
        .listGeneralJournal(params)
        .then(res => {
          this.dataListJournal = res;
          this.dataListJournal.data.forEach((dataObject, index) => {
            dataObject["key"] = index;
            dataObject.date = this.moment(dataObject.date).format(
              DEFAULT_DATE_FORMAT
            );
          });
        })
        .finally(() => (this.loadingTable = false));
    },

    handleReset() {
      this.form.resetFields();
    },
  },
});
