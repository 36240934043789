
























































































































































import SearchBuilder from "@/builder/SearchBuilder";
import { APagination } from "@/hooks/table";
import MNotification from "@/mixins/MNotification.vue";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import {
  IPrivilege,
  RequestPrivilegeCreate,
  RequestPrivilegeUpdate,
} from "@/models/interface/privilege.interface";
import { userServices } from "@/services/user.services";
import { SorterProps } from "@/types";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";

interface PrivilegeFormData {
  authority: string;
  description: string;
  isActive: boolean;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

@Component({
  name: "PrivilegeIndexPage",
})
export default class PrivilegeIndexPage extends Mixins(MNotification) {
  @Ref() readonly privilegeForm!: FormModel;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  private loading = false;
  private submitting = false;
  private modalVisible = false;
  private isEdit = false;
  private privileges: IPrivilege[] = [];
  private pagination = {
    total: 0,
    page: 1,
    limit: 10,
    sorts: "authority:asc" as string | undefined,
    search: undefined as string | undefined,
  };
  private searchQuery = "";
  private selectedId: string | null = null;

  // Form data
  private formData: PrivilegeFormData = {
    authority: "",
    description: "",
    isActive: true,
    create: true,
    read: true,
    update: true,
    delete: true,
  };

  // Form validation rules
  private rules = {
    authority: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "blur",
      },
    ],
    description: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "blur",
      },
    ],
  };

  // Table columns
  private get columns() {
    return [
      {
        title: this.$t("lbl_authority"),
        dataIndex: "authority",
        sorter: true,
        defaultSortOrder: "ascend",
        width: "25%",
      },
      {
        title: this.$t("lbl_description"),
        dataIndex: "description",
        width: "45%",
      },
      {
        title: this.$t("lbl_status"),
        dataIndex: "active",
        scopedSlots: { customRender: "status" },
        width: "15%",
      },
      {
        scopedSlots: { customRender: "action" },
        align: "right",
        width: "15%",
      },
    ];
  }

  mounted() {
    this.fetchPrivileges();
  }

  // Methods
  private async fetchPrivileges() {
    try {
      this.loading = true;
      const response = await userServices.listOfPrivilege({
        page: this.pagination.page - 1,
        limit: this.pagination.limit,
        search: this.pagination.search,
        sorts: this.pagination.sorts,
      });

      this.privileges = response.data;
      this.pagination.total = response.totalElements;
    } catch (error) {
      this.showNotifError("notif_process_fail");
    } finally {
      this.loading = false;
    }
  }

  private handleSearch() {
    this.pagination.search = undefined;
    if (this.searchQuery) {
      this.pagination.search = new SearchBuilder()
        .push(["authority", this.searchQuery], { like: "both" })
        .build();
    }
    this.pagination.page = 1;
    this.fetchPrivileges();
  }

  private handleTableChange(
    { current, pageSize }: APagination,
    _,
    { columnKey, order }: SorterProps
  ) {
    const direction = order === "ascend" ? "asc" : "desc";
    this.pagination.limit = pageSize;
    const hasNextPage = pageSize === this.pagination.limit;
    this.pagination.page = hasNextPage ? current : 1;
    if (columnKey) {
      this.pagination.sorts = `${columnKey}:${direction}`;
    }
    this.fetchPrivileges();
  }

  private handleShowCreateModal() {
    this.isEdit = false;
    this.resetForm();
    this.modalVisible = true;
  }

  private handleEdit(record: IPrivilege) {
    this.isEdit = true;
    this.selectedId = record.id;
    this.formData = {
      authority: record.authority,
      description: record.description,
      isActive: record.active,
      create: false,
      read: false,
      update: false,
      delete: false,
    };
    this.modalVisible = true;
  }

  private handleModalCancel() {
    this.modalVisible = false;
    this.resetForm();
  }

  private resetForm() {
    this.selectedId = null;
    if (this.privilegeForm !== undefined) {
      this.privilegeForm.resetFields();
    }
  }

  buildScope(
    authority: string,
    action: "create" | "read" | "update" | "delete"
  ): string {
    return `${authority}:${action}`;
  }

  buildCreateDto(state: PrivilegeFormData): RequestPrivilegeCreate {
    const scope: string[] = [];
    if (state.create) scope.push(this.buildScope(state.authority, "create"));
    if (state.read) scope.push(this.buildScope(state.authority, "read"));
    if (state.update) scope.push(this.buildScope(state.authority, "update"));
    if (state.delete) scope.push(this.buildScope(state.authority, "delete"));
    return {
      scope,
      authority: state.authority,
      description: state.description,
      active: state.isActive,
    };
  }

  buildUpdateDto(state: PrivilegeFormData): RequestPrivilegeUpdate {
    return {
      authority: state.authority,
      description: state.description,
      active: state.isActive,
    };
  }

  private async handleSubmit() {
    try {
      await this.privilegeForm.validate();

      this.submitting = true;
      if (this.isEdit) {
        await userServices.updatePrivilege(
          this.selectedId as string,
          this.buildUpdateDto(this.formData)
        );
        this.showNotifSuccess("notif_update_success");
      } else {
        await userServices.createPrivilege(this.buildCreateDto(this.formData));
        this.showNotifSuccess("notif_create_success");
      }

      this.modalVisible = false;
      this.resetForm();
      this.fetchPrivileges();
    } catch (error) {
      this.showNotifError("notif_process_fail");
    } finally {
      this.submitting = false;
    }
  }
}
