var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "privilege-page" },
    [
      _c(
        "a-card",
        { attrs: { bordered: false, title: _vm.$t("lbl_privilege") } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-row",
                {
                  attrs: { gutter: 16, type: "flex", justify: "space-between" }
                },
                [
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c("a-input-search", {
                        attrs: {
                          placeholder: _vm.$t("common.search-by-text", {
                            text: _vm.$t("lbl_authority")
                          })
                        },
                        on: { search: _vm.handleSearch },
                        model: {
                          value: _vm.searchQuery,
                          callback: function($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24, align: "end" } },
                    [
                      _vm.$can("create", "privilege")
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleShowCreateModal }
                            },
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_create_new")) + " "
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              dataSource: _vm.privileges,
              columns: _vm.columns,
              pagination: {
                showTotal: function() {
                  return _vm.$t("lbl_total_items", {
                    total: _vm.pagination.total
                  })
                },
                total: _vm.pagination.total,
                showSizeChanger: true,
                pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                current: _vm.pagination.page,
                pageSize: _vm.pagination.limit
              },
              scroll: { x: 1000 },
              size: "small",
              "row-key": "id",
              "row-class-name": function(_record, index) {
                return index % 2 ? "bg-white" : "bg-gray-light"
              }
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(text) {
                  return [
                    _c("a-badge", {
                      attrs: { status: text ? "success" : "error" }
                    }),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          text ? _vm.$t("lbl_active") : _vm.$t("lbl_inactive")
                        )
                      )
                    ])
                  ]
                }
              },
              {
                key: "action",
                fn: function(text, record) {
                  return [
                    _vm.$can("update", "privilege")
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "link", icon: "edit" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(record)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("lbl_edit")) + " ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.isEdit
              ? _vm.$t("common.edit-text", { text: _vm.$t("lbl_privilege") })
              : _vm.$t("common.create-text", { text: _vm.$t("lbl_privilege") }),
            maskClosable: false,
            width: 720,
            okText: _vm.$t("lbl_save"),
            cancelText: _vm.$t("lbl_cancel"),
            destroyOnClose: true,
            okButtonProps: { props: { loading: _vm.submitting } }
          },
          on: { cancel: _vm.handleModalCancel, ok: _vm.handleSubmit },
          model: {
            value: _vm.modalVisible,
            callback: function($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: 16, type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { flex: "1" } },
                [
                  _c(
                    "a-form-model",
                    {
                      ref: "privilegeForm",
                      attrs: {
                        layout: "horizontal",
                        model: _vm.formData,
                        rules: _vm.rules
                      }
                    },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_authority"),
                            prop: "authority"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_authority")
                              })
                            },
                            model: {
                              value: _vm.formData.authority,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "authority", $$v)
                              },
                              expression: "formData.authority"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_description"),
                            prop: "description"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              rows: 4,
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_description")
                              })
                            },
                            model: {
                              value: _vm.formData.description,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "description", $$v)
                              },
                              expression: "formData.description"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_status") } },
                        [
                          _c("a-switch", {
                            model: {
                              value: _vm.formData.isActive,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "isActive", $$v)
                              },
                              expression: "formData.isActive"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.isEdit
                ? _c(
                    "a-col",
                    { attrs: { flex: "1" } },
                    [
                      _c(
                        "a-form-model",
                        { attrs: { layout: "vertical" } },
                        [
                          _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_permissions") } },
                            [
                              _c(
                                "a-card",
                                { attrs: { bordered: false, size: "small" } },
                                [
                                  _c(
                                    "a-row",
                                    { attrs: { gutter: [0, 16] } },
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c("a-switch", {
                                            attrs: { disabled: _vm.isEdit },
                                            model: {
                                              value: _vm.formData.create,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "create",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.create"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "permission-label" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_create"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c("a-switch", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.formData.read,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "read",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.read"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "permission-label" },
                                            [_vm._v(_vm._s(_vm.$t("lbl_read")))]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c("a-switch", {
                                            attrs: { disabled: _vm.isEdit },
                                            model: {
                                              value: _vm.formData.update,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "update",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.update"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "permission-label" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_update"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c("a-switch", {
                                            attrs: { disabled: _vm.isEdit },
                                            model: {
                                              value: _vm.formData.delete,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "delete",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.delete"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "permission-label" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("lbl_delete"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }