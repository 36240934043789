var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_general_journal") } },
    [
      _c(
        "a-form",
        _vm._b(
          {
            attrs: { form: _vm.form, "label-align": "left" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submitForm.apply(null, arguments)
              }
            }
          },
          "a-form",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.docNumber.label) } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.docNumber.decorator,
                            expression: "formRules.docNumber.decorator"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            _vm.formRules.docNumber.placeholder
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.dateFrom.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.dateFrom.decorator,
                            expression: "formRules.dateFrom.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: _vm.$t(
                            _vm.formRules.dateFrom.placeholder
                          ),
                          format: _vm.DEFAULT_DATE_FORMAT
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.dateTo.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.dateTo.decorator,
                            expression: "formRules.dateTo.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: _vm.$t(_vm.formRules.dateTo.placeholder),
                          format: _vm.DEFAULT_DATE_FORMAT
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.documentReference.label)
                      }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.documentReference.decorator,
                            expression: "formRules.documentReference.decorator"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            _vm.formRules.documentReference.placeholder
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.status.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.status.decorator,
                              expression: "formRules.status.decorator"
                            }
                          ],
                          attrs: {
                            placeholder: _vm.$t(
                              _vm.formRules.status.placeholder
                            ),
                            "allow-clear": ""
                          }
                        },
                        _vm._l(_vm.journalStatuses, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(_vm._s(item.value))]
                                ),
                                _vm._v(" " + _vm._s(item.value) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.source.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.source.decorator,
                              expression: "formRules.source.decorator"
                            }
                          ],
                          attrs: {
                            placeholder: _vm.$t(
                              _vm.formRules.source.placeholder
                            ),
                            "allow-clear": ""
                          }
                        },
                        _vm._l(_vm.journalSources, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(_vm._s(item.value))]
                                ),
                                _vm._v(" " + _vm._s(item.value) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.description.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.description.decorator,
                            expression: "formRules.description.decorator"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            _vm.formRules.description.placeholder
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              attrs: {
                gutter: [16, 16],
                type: "flex",
                justify: "space-between"
              }
            },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { "html-type": "button" },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loadingTable,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$can("create", "general-journal")
                ? _c(
                    "a-col",
                    [
                      _c(
                        "RouterLink",
                        { attrs: { to: { name: "generaljournal.create" } } },
                        [
                          _c("a-button", { attrs: { type: "primary" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_create")) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("a-form-item")
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  idtable: "table1",
                  onSelectChange: null,
                  selectedRowKeys: null,
                  dataSource: _vm.dataListJournal.data,
                  columns: _vm.columnsTable,
                  scroll: { x: "100%" },
                  size: "small",
                  paginationcustom: true,
                  defaultPagination: false,
                  loading: _vm.loadingTable
                },
                on: {
                  "on-view": _vm.reponseViewTable,
                  "on-edit": _vm.reponseViewTable
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.dataListJournal.totalElements,
                  pageSizeSet: _vm.limit,
                  "default-current": _vm.currentPage,
                  idPagination: "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "a-tag",
                {
                  staticStyle: { "font-size": "13px" },
                  attrs: { color: "#8c8c8c" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lbl_total_found")) +
                      " : " +
                      _vm._s(_vm.dataListJournal.totalElements || 0) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }